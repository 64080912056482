<template>
  <section>
    <div class="wrap" ref="wrap">
      <div
          class="banner"
          ref="banner"
          style="background-image: url('/assets/home/4-observation.png')"
      ></div>
      <div ref="content" class="content">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 858.06 113.58"
            class="headings"
        >
          <path
              ref="path"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M48.42-20.7c0,3.78-1.08,4.86-5.04,4.86H33.84c-3.96,0-5.22-1.08-5.22-4.86V-92.88c0-3.78,1.26-4.86,5.22-4.86h9.54c3.96,0,5.04,1.08,5.04,4.86ZM27.54-113.58c-13.86,0-19.62,6.48-19.62,17.82v77.94C7.92-6.48,13.68,0,27.54,0H49.5C63.36,0,69.12-6.48,69.12-17.82V-95.76c0-11.34-5.76-17.82-19.62-17.82Zm67.32,0V0h43.2c13.86,0,19.62-6.48,19.62-17.82V-44.46c0-7.02-2.16-11.16-7.56-12.96,5.4-1.8,7.56-6.84,7.56-13.86V-95.76c0-11.34-5.76-17.82-19.62-17.82Zm42.3,93.6c0,3.78-1.26,4.86-5.22,4.86H115.56V-49.86h16.38c3.96,0,5.22.9,5.22,4.86Zm0-49.86c0,3.78-1.26,4.86-5.22,4.86H115.56V-98.46h16.38c3.96,0,5.22,1.08,5.22,4.86Zm81.9,49.14c0,3.78-1.08,4.86-5.04,4.86h-6.84c-3.96,0-5.22-1.08-5.22-4.86V-33.48h-20.7v15.66C181.26-6.48,187.02,0,200.88,0h19.44c13.86,0,19.62-6.48,19.62-17.82v-29.7c0-11.34-5.4-17.64-18.9-17.64H206.82c-3.96,0-5.22-1.08-5.22-4.86V-92.88c0-3.78,1.26-4.86,5.22-4.86h6.84c3.96,0,5.04,1.08,5.04,4.86v12.96h20.88V-95.76c0-11.34-5.76-17.82-19.44-17.82h-19.8c-13.86,0-19.44,6.48-19.44,17.82v27.9c0,11.34,5.04,18,18.54,18h14.58c3.96,0,5.04,1.08,5.04,4.86Zm65.7,4.86V-50.22h27.9V-66.24h-27.9v-31.5h33.12v-15.84h-54V0h55.44V-15.84Zm76.14-81.9h14.94c3.96,0,5.04,1.08,5.04,4.86v28.44c0,3.78-1.08,4.86-5.04,4.86H360.9ZM385.02,0h22.32l-18.9-44.82c9.18-1.8,13.14-7.92,13.14-17.28V-95.76c0-11.34-5.76-17.82-19.62-17.82H340.2V0h20.7V-44.28h6.84Zm84.96-113.58-14.94,91.8-14.58-91.8H419.04L441.54,0h27.18l22.5-113.58ZM530.1-39.24l9.36-54.36,9.9,54.36ZM556.56,0h20.88L553.5-113.58H525.6L502.74,0h20.7l3.96-23.58h24.84Zm92.7-113.58H588.78v15.84h19.8V0h20.7V-97.74h19.98Zm19.62,0V0h20.7V-113.58ZM756.72-20.7c0,3.78-1.08,4.86-5.04,4.86h-9.54c-3.96,0-5.22-1.08-5.22-4.86V-92.88c0-3.78,1.26-4.86,5.22-4.86h9.54c3.96,0,5.04,1.08,5.04,4.86Zm-20.88-92.88c-13.86,0-19.62,6.48-19.62,17.82v77.94C716.22-6.48,721.98,0,735.84,0H757.8c13.86,0,19.62-6.48,19.62-17.82V-95.76c0-11.34-5.76-17.82-19.62-17.82Zm111.24,0v74.16l-23.94-74.16H803.16V0h19.08V-75.24L846.54,0h19.44V-113.58Z"
              transform="translate(-7.92 113.58)"
          />
        </svg>

        <p ref="para1">
          We harness the best satellite technology available, providing
          customers with information-as-a-service – and high-resolution
          visibility of the livestock, wildlife and habitat.
        </p>

        <next ref="next" target="#s5"></next>
      </div>
    </div>
  </section>
</template>
<script>
import {defineComponent} from 'vue'
import {gsap} from 'gsap'
//import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Next from '@/components/Next'

export default defineComponent({
  name: 'Banner4',
  components: {Next},
  mounted() {
    const {wrap, banner, path, para1} = this.$refs
    const next = this.$refs.next.$el

    // Scroll to the start
    // ScrollTrigger.create({
    //   trigger: wrap,
    //   start: 'top bottom',
    //   onEnter: () => {
    //     gsap.to(window, {
    //       scrollTo: { y: wrap, autoKill: false },
    //       duration: 1,
    //     })
    //   },
    // })

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrap,
        start: 'top 50px',
        end: 'bottom top',
        pin: true,
        pinSpacing: false,
        anticipatePin: true,
        vars: {
          group: 'g1',
        },
      },
    })
    tl.to(banner, {opacity: 0.5})
    tl.from(path, {drawSVG: '50% 50%', duration: 2}, '-=1')
    tl.to(path, {fill: '#FFF', duration: 2}, '-=1')
    tl.to(path, {stroke: 'transparent'})
    tl.fromTo(
        para1,
        {opacity: 0, y: 10},
        {opacity: 1, y: 0, ease: 'power1.out'},
        '-=1'
    )
    tl.fromTo(
        next,
        {autoAlpha: 0},
        {autoAlpha: 1, ease: 'power1.out', duration: 2},
        '+=1'
    )

    //ScrollTrigger.refresh(true)
  },
})
</script>
<style lang="scss" scoped>
section {
  .wrap {
    height: 200vh;

    .headings {
      max-width:90%;
      height: 70px;
      @screen md {
        height: 115px;
      }
    }
  }
}


</style>
