<template>
  <div id="nav" class="hidden md:flex bg-black">
    <div class="container mx-auto flex items-center">
      <div class="flex-1 flex items-center justify-start text-white">
        <router-link class="mr-4" to="/about">About</router-link>|
        <router-link class="mx-4" to="/services">Services</router-link>|
        <router-link class="ml-4" to="/contact">Contact</router-link>
      </div>

      <!--      <a href="/" class="flex-1"-->
      <!--      ><img class="mx-auto" src="../assets/logo.svg"-->
      <!--      /></a>-->

      <router-link to="/"
        ><img class="mx-auto" src="../assets/logo.svg"
      /></router-link>

      <div class="flex-1 flex items-center justify-end">
        <a
          href="https://www.linkedin.com/company/eolas-insight-ltd/"
          target="_blank"
          title="LinkedIn"
          class="social linked-in"
          >LinkedIn</a
        >
        <!--        <a href="" title="Twitter" class="social twitter">Twitter</a>-->
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MainNavigation',
})
</script>
<style lang="scss" scoped>
#nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  @apply h-20;

  a {
    @apply text-white uppercase;

    &.router-link-exact-active {
      @apply text-primary;
    }

    &.social {
      background-position: center;
      background-repeat: no-repeat;
      width: 32px;
      height: 32px;
      text-indent: -9999px;

      &.linked-in {
        background-image: url('../assets/linkedin.svg');
      }
      &.twitter {
        background-image: url('../assets/twitter.svg');
      }
    }
  }
}
</style>
