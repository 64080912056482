<template>
  <section>
    <div class="wrap" ref="wrap">
      <div
        class="banner"
        ref="banner"
        style="background-image: url('/assets/home/6-animal.jpg')"
      ></div>
      <div ref="content" class="content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 480.24 113.58"
          class="headings"
        >
          <g class="group-1">
            <path
              ref="path1a"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M50.76 0h-27.9L0 113.58h20.7l4-23.58h24.8l4.32 23.58H74.7zm-23.4 74.34L36.72 20l9.9 54.36z"
            />
            <path
              ref="path1b"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M137.7 0v74.16L113.76 0H93.78v113.58h19.08V38.34l24.3 75.24h19.44V0h-18.9z"
            />
            <path
              ref="path1c"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M184.14 0h20.7v113.58h-20.7z"
            />
            <path
              ref="path1d"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M282.42 88.92l15.12-55.08v79.74h18.18V0h-22.68l-19.08 67.5L254.88 0H232.2v113.58h18.36V34.02l15.12 54.9h16.74z"
            />
            <path
              ref="path1e"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M385.74 0h-27.9L335 113.58h20.7l4-23.58h24.84l4.32 23.58h20.88zm-23.4 74.34L371.7 20l9.9 54.36z"
            />
            <path
              ref="path1f"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M449.46 97.74V0h-20.7v113.58h50.94V97.74z"
            />
          </g>
        </svg>

        <p ref="para1">
          Our innovative animal recognition technology enables accurate,
          tagless, monitoring of livestock and wildlife, from deer in the
          Scottish Highlands to camels in the Arabian desert.
        </p>

        <next ref="next" target="#s6b"></next>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from 'vue'
import { gsap } from 'gsap'
//import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Next from '@/components/Next'

export default defineComponent({
  name: 'Banner6a',
  components: { Next },
  mounted() {
    const {
      wrap,
      banner,
      path1a,
      path1b,
      path1c,
      path1d,
      path1e,
      path1f,
      para1,
    } = this.$refs
    const next = this.$refs.next.$el

    // Scroll to the start
    // ScrollTrigger.create({
    //   trigger: wrap,
    //   start: 'top bottom',
    //   onEnter: () => {
    //     gsap.to(window, {
    //       scrollTo: { y: wrap, autoKill: false },
    //       duration: 1,
    //     })
    //   },
    // })

    let tl = gsap.timeline({
      scrollTrigger: {
        //markers: true,
        trigger: wrap,
        start: 'top 50px',
        pin: true,
        pinSpacing: false,
        anticipatePin: true,
        vars: {
          group: 'g1',
        },
      },
    })
    tl.to(banner, { opacity: 0.5 })
    tl.from(path1a, { drawSVG: '50% 50%', duration: 2 }, '-=1')
    tl.from(path1b, { drawSVG: '50% 50%', duration: 2 }, '-=2')
    tl.from(path1c, { drawSVG: '50% 50%', duration: 2 }, '-=2')
    tl.from(path1d, { drawSVG: '50% 50%', duration: 2 }, '-=2')
    tl.from(path1e, { drawSVG: '50% 50%', duration: 2 }, '-=2')
    tl.from(path1f, { drawSVG: '50% 50%', duration: 2 }, '-=2')
    tl.to(path1a, { fill: '#FFF', duration: 2 }, '-=1')
    tl.to(path1b, { fill: '#FFF', duration: 2 }, '-=2')
    tl.to(path1c, { fill: '#FFF', duration: 2 }, '-=2')
    tl.to(path1d, { fill: '#FFF', duration: 2 }, '-=2')
    tl.to(path1e, { fill: '#FFF', duration: 2 }, '-=2')
    tl.to(path1f, { fill: '#FFF', duration: 2 }, '-=2')
    tl.to(path1a, { stroke: 'transparent', duration: 0.5 })
    tl.to(path1b, { stroke: 'transparent', duration: 0.5 }, '-=0.5')
    tl.to(path1c, { stroke: 'transparent', duration: 0.5 }, '-=0.5')
    tl.to(path1d, { stroke: 'transparent', duration: 0.5 }, '-=0.5')
    tl.to(path1e, { stroke: 'transparent', duration: 0.5 }, '-=0.5')
    tl.to(path1f, { stroke: 'transparent', duration: 0.5 }, '-=0.5')

    tl.fromTo(
      para1,
      { opacity: 0, y: 10 },
      { opacity: 1, y: 0, ease: 'power1.out' },
      '-=1'
    )
    tl.fromTo(
      next,
      { autoAlpha: 0 },
      { autoAlpha: 1, ease: 'power1.out', duration: 2 },
      '+=1'
    )

    //ScrollTrigger.refresh(true)
  },
})
</script>
<style lang="scss" scoped>
section {
  .wrap {
    height: 200vh;

    .headings {
      max-width:90%;
      height: 70px;
      @screen md {
        height: 115px;
      }
    }
  }
}
</style>
