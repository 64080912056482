<template>
  <div class="footer z-50">
    <div class="inline-block">©2021 Eolas</div>
<!--    <div class="inline-block">©2021 Eolas<span> |</span></div>-->
<!--    <router-link to="/privacy-policy">Privacy Policy</router-link><span> |</span>-->
<!--    <router-link to="/terms-of-use">Website Terms of Use</router-link>-->
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SiteFooter',
})
</script>
<style lang="scss" scoped>
.footer {
  @apply absolute bottom-0 left-0 flex flex-col w-full bg-black text-white text-center py-2;

  @screen sm {
    @apply block;
  }

  span {
    @apply hidden;
    @screen md {
      @apply inline-block mx-2;
    }
  }
}
</style>
