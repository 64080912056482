<template>
  <div class="home overflow-x-hidden">
    <banner1 id="s1"></banner1>
    <banner2 id="s2"></banner2>
    <banner3 id="s3"></banner3>
    <banner4 id="s4"></banner4>
    <banner5 id="s5"></banner5>
    <banner6a id="s6a"></banner6a>
    <banner6b id="s6b"></banner6b>
    <banner8 id="s8"></banner8>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin'

import Banner1 from '@/views/banner/Banner1'
import Banner2 from '@/views/banner/Banner2'
import Banner3 from '@/views/banner/Banner3'
import Banner4 from '@/views/banner/Banner4'
import Banner5 from '@/views/banner/Banner5'
import Banner6a from '@/views/banner/Banner6a'
import Banner6b from '@/views/banner/Banner6b'
import Banner8 from '@/views/banner/Banner8'
gsap.registerPlugin(
  ScrollTrigger,
  DrawSVGPlugin,
  ScrollToPlugin,
  MorphSVGPlugin
)

ScrollTrigger.defaults({
  toggleActions: 'play complete resume pause',
})

export default defineComponent({
  name: 'Home',
  components: {
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5,
    Banner6a,
    Banner6b,
    Banner8,
  },
  mounted() {
    ScrollTrigger.refresh(true)
  },
})
</script>
