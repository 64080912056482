<template>
  <section>
    <div class="wrap" ref="wrap">
      <div
        class="banner"
        ref="banner"
        style="background-image: url('/assets/home/3-earth.jpg')"
      ></div>
      <div ref="content" class="content">
        <div class="flex flex-col px-8 md:px-0">
          <svg
            ref="svg1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 564 158"
            class="svg1"
          >
            <path
              ref="path1"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M22.32 67.94h32.59v22.51H22.32v45.02h38.21v22.51H0V0h60.47v22.52H22.32v45.42z"
            />
            <path
              ref="path2"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M186.11 3.16a10.45 10.45 0 013.06 7.51v136.85A10.45 10.45 0 01178.73 158h-44.06a10.45 10.45 0 01-10.47-10.44V10.67a10.41 10.41 0 013.06-7.51 9.93 9.93 0 017.4-3.16h44a10 10 0 017.45 3.16zm-19.26 19.36h-20.14v113.15h20.14z"
            />
            <path
              ref="path3"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M290.34 135.47h36.59l-2.48 22.52h-56.62V0h22.51v135.47z"
            />
            <path
              ref="path4"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M397.45 136.46L395.08 158h-22.51l17-158h40.88l17 158h-22.74l-2.37-21.53zm12.44-116.32l-10.06 93.8H420z"
            />
            <path
              ref="path5"
              fill="transparent"
              stroke="#FFF"
              stroke-width="2"
              d="M541 22.52h-20v42.65h33.37v.4a10.4 10.4 0 016.52 3.55 10.28 10.28 0 012.59 6.88v71.49a10.28 10.28 0 01-2.57 6.91 9.58 9.58 0 01-6.52 3.36v.2H509a10.22 10.22 0 01-6.51-2.17 10.45 10.45 0 01-3.95-8.3v-39.1H521v27.05h20V88.07q-5.34 0-17.18-.1t-17.58-.3a13.87 13.87 0 01-3.75-1.77 10.43 10.43 0 01-3.95-8.3V10.47a10 10 0 011.58-5.33 10.32 10.32 0 013.75-3.76A10.07 10.07 0 01509 0h44a10.45 10.45 0 0110.47 10.44v33.4H541z"
            />
          </svg>

          <div ref="textWrap" class="text flex justify-between">
            <div ref="text1">EARTH</div>
            <div ref="text2">OBSERVATION</div>
            <div ref="text3">LAND</div>
            <div ref="text4">ANIMAL</div>
            <div ref="text5">SURVEY</div>
          </div>
        </div>

        <next ref="next1" target="#s3b"></next>
        <!-- EARTH -->
        <div id="s3b"></div>
        <svg
          ref="svg2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 385.38 113.58"
          class="headings"
        >
          <path
            d="M29.52-15.84V-50.22h27.9V-66.24H29.52v-31.5H62.64v-15.84h-54V0H64.08V-15.84Zm75.6-23.4,9.36-54.36,9.9,54.36ZM131.58,0h20.88L128.52-113.58h-27.9L77.76,0h20.7l3.96-23.58h24.84Zm60.66-97.74h14.94c3.96,0,5.04,1.08,5.04,4.86v28.44c0,3.78-1.08,4.86-5.04,4.86H192.24ZM216.36,0h22.32l-18.9-44.82c9.18-1.8,13.14-7.92,13.14-17.28V-95.76c0-11.34-5.76-17.82-19.62-17.82H171.54V0h20.7V-44.28h6.84Zm94.32-113.58H250.2v15.84H270V0h20.7V-97.74h19.98Zm62.64,0v49.32h-23.4v-49.32h-20.7V0h20.7V-48.42h23.4V0h20.7V-113.58Z"
            transform="translate(-8.64 113.58)"
            fill="#fff"
          />
        </svg>

        <p ref="para1">
          This is our planet. This is our space. We work with people who wish to
          preserve and nurture it.
        </p>

        <next ref="next2" target="#s4"></next>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from 'vue'
import { gsap } from 'gsap'
//import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Next from '@/components/Next'

export default defineComponent({
  name: 'Banner3',
  components: { Next },
  mounted() {
    const {
      wrap,
      //wrap2,
      banner,
      // content,
      path1,
      path2,
      path3,
      path4,
      path5,
      text1,
      text2,
      text3,
      text4,
      text5,
      textWrap,
      svg1,
      svg2,
      para1,
    } = this.$refs
    const next1 = this.$refs.next1.$el
    const next2 = this.$refs.next2.$el

    // Scroll to the start
    // ScrollTrigger.create({
    //   trigger: wrap,
    //   start: 'top bottom',
    //   onEnter: () => {
    //     gsap.to(window, {
    //       scrollTo: { y: wrap, autoKill: false },
    //       duration: 1,
    //     })
    //   },
    // })

    let tl = gsap.timeline({
      scrollTrigger: {
        //markers: true,
        trigger: wrap,
        start: 'top 50px',
        end: 'bottom bottom',
        pin: true,
        pinSpacing: false,
        //anticipatePin: true,
        scrub: 1,
        snap: {
          snapTo: 'labelsDirectional', // snap to the closest label in the timeline
          duration: { min: 0.1, max: 3 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
          delay: 0.1, // wait 0.2 seconds from the last scroll event before doing the snapping
          //ease: 'power1.inOut', // the ease of the snap animation ("power3" by default)
          inertia: false,
        },
        vars: {
          group: 'g1',
        },
      },
    })

    tl.to(banner, { opacity: 0.5, duration: 3 })
    tl.from(path1, { drawSVG: '50% 50%', duration: 3 }, '-=3')
    tl.from(path2, { drawSVG: '50% 50%', duration: 3 }, '-=3')
    tl.from(path3, { drawSVG: '50% 50%', duration: 3 }, '-=3')
    tl.from(path4, { drawSVG: '50% 50%', duration: 3 }, '-=3')
    tl.from(path5, { drawSVG: '50% 50%', duration: 3 }, '-=3')

    tl.to(path1, {
      stroke: 'transparent',
      fill: '#FFF',
      duration: 1,
      ease: 'power1.out',
    })
    tl.fromTo(
      text1,
      { opacity: 0, y: 10 },
      { opacity: 1, y: 0, ease: 'power1.out' },
      '-=1'
    )

    tl.to(
      path2,
      { stroke: 'transparent', fill: '#FFF', duration: 1, ease: 'power1.out' },
      '-=0.5'
    )
    tl.fromTo(
      text2,
      { opacity: 0, y: 10 },
      { opacity: 1, y: 0, ease: 'power1.out' },
      '-=1'
    )

    tl.to(
      path3,
      { stroke: 'transparent', fill: '#FFF', duration: 1, ease: 'power1.out' },
      '-=0.5'
    )
    tl.fromTo(
      text3,
      { opacity: 0, y: 10 },
      { opacity: 1, y: 0, ease: 'power1.out' },
      '-=1'
    )

    tl.to(
      path4,
      { stroke: 'transparent', fill: '#FFF', duration: 1, ease: 'power1.out' },
      '-=0.5'
    )
    tl.fromTo(
      text4,
      { opacity: 0, y: 10 },
      { opacity: 1, y: 0, ease: 'power1.out' },
      '-=1'
    )

    tl.to(
      path5,
      { stroke: 'transparent', fill: '#FFF', duration: 1, ease: 'power1.out' },
      '-=0.5'
    )
    tl.fromTo(
      text5,
      { opacity: 0, y: 10 },
      { opacity: 1, y: 0, ease: 'power1.out' },
      '-=1'
    )

    tl.fromTo(
      next1,
      { autoAlpha: 0 },
      { autoAlpha: 1, ease: 'power1.out', duration: 2 },
      '+=1'
    )

    tl.addLabel('mid')

    tl.to([svg1, textWrap, next1], { opacity: 0, display: 'none' })

    tl.to(banner, { scale: 2, duration: 2 })

    tl.fromTo(
      svg2,
      { display: 'none', opacity: 0 },
      { display: 'block', opacity: 1, duration: 1 }
    )
    tl.fromTo(
      para1,
      { display: 'none', opacity: 0 },
      { display: 'block', opacity: 1, duration: 2 },
      '-=1'
    )

    tl.fromTo(
      next2,
      { autoAlpha: 0 },
      { autoAlpha: 1, ease: 'power1.out', duration: 2 },
      '+=1'
    )

    tl.addLabel('end')

    //ScrollTrigger.refresh(true)
  },
})
</script>
<style lang="scss" scoped>
section {
  .wrap {
    height: 500vh;

    .content {
      .svg1 {
        @apply mt-20 w-full;
        @screen md {
          width: 564px;
        }
      }

      //@apply mt-20;
      .text {
        @apply max-w-xl mb-8 text-white text-center text-sm;
        @screen md {
          @apply text-lg;
        }
      }
    }

    .headings {
      max-width:90%;
      height: 70px;
      @screen md {
        height: 115px;
      }
    }
  }
}
</style>
