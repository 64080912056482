<template>
  <div :target="target" @click="go" class="next">
    <span :class="textColour" class="text-base uppercase" v-text="label"></span>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 40">
      <path
        :class="textColour"
        class="path1"
        stroke-width="2"
        d="M42.15 3.51l-19.95 20-20-20-.74.73L22.2 24.93 42.89 4.24z"
      />
      <path
        :class="textColour"
        class="path2"
        d="M38.77 0L22.2 16.56 5.64 0 4.9.73 22.2 18 39.5.73z"
      />
    </svg>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { gsap } from 'gsap'

export default defineComponent({
  name: 'Next',
  props: {
    target: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    colour: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    textColour() {
      return 'text-' + this.colour
    },
  },
  methods: {
    go() {
      if (this.target) {
        gsap.to(window, {
          scrollTo: this.target,
          duration: 1,
          ease: 'circ.out',
        })
      }
    },
  },
})
</script>
<style lang="scss" scoped>
.next {
  @apply text-center mt-4 cursor-pointer;
  svg {
    @apply w-10 mx-auto mt-4;
    path {
      @apply fill-current stroke-current;
      animation: moveDown 2s infinite;
      &.path1 {
        animation: moveDown2 2s infinite;
        animation-delay: 0.4s;
      }
    }
  }
}

$maxOpacity: 0.75;

@keyframes moveDown {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: $maxOpacity;
  }
  100% {
    transform: translateY(5px);
    opacity: 0;
  }
}

@keyframes moveDown2 {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: $maxOpacity;
  }
  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}
</style>
