<template>
  <section>
    <div class="wrap" ref="wrap">
      <div
        class="banner"
        ref="banner"
        style="background-image: url('/assets/home/5-land.jpg')"
      ></div>
      <div ref="content" class="content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 308.7 113.58"
          class="headings"
        >
          <path
            ref="path"
            fill="transparent"
            stroke="#FFF"
            stroke-width="2"
            d="M29.34-15.84v-97.74H8.64V0H59.58V-15.84Zm68.58-23.4,9.36-54.36,9.9,54.36ZM124.38,0h20.88L121.32-113.58H93.42L70.56,0h20.7l3.96-23.58h24.84Zm83.88-113.58v74.16l-23.94-74.16H164.34V0h19.08V-75.24L207.72,0h19.44V-113.58Zm88.2,93.6c0,3.78-1.08,4.86-5.04,4.86h-16.2V-98.46h16.2c3.96,0,5.04,1.08,5.04,4.86Zm-41.94-93.6V0h43.2c13.86,0,19.62-6.48,19.62-17.82V-95.76c0-11.34-5.76-17.82-19.62-17.82Z"
            transform="translate(-8.64 113.58)"
          />
        </svg>

        <p ref="para1">
          We watch over it. We measure it. We watch it change and develop. Our
          data analysis can help protect it.
        </p>

        <next ref="next" target="#s6a"></next>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from 'vue'
import { gsap } from 'gsap'
//import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Next from '@/components/Next'

export default defineComponent({
  name: 'Banner5',
  components: { Next },
  mounted() {
    const { wrap, banner, path, para1 } = this.$refs
    const next = this.$refs.next.$el

    // Scroll to the start
    // ScrollTrigger.create({
    //   trigger: wrap,
    //   start: 'top bottom',
    //   onEnter: () => {
    //     gsap.to(window, {
    //       scrollTo: { y: wrap, autoKill: false },
    //       duration: 1,
    //     })
    //   },
    // })

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrap,
        start: 'top 50px',
        end: 'bottom top',
        pin: true,
        pinSpacing: false,
        anticipatePin: true,
        vars: {
          group: 'g1',
        },
      },
    })
    tl.to(banner, { opacity: 0.5 })
    tl.from(path, { drawSVG: '50% 50%', duration: 2 }, '-=1')
    tl.to(path, { fill: '#FFF', duration: 2 }, '-=1')
    tl.to(path, { stroke: 'transparent' })
    tl.fromTo(
      para1,
      { opacity: 0, y: 10 },
      { opacity: 1, y: 0, ease: 'power1.out' },
      '-=1'
    )
    tl.fromTo(
      next,
      { autoAlpha: 0 },
      { autoAlpha: 1, ease: 'power1.out', duration: 2 },
      '+=1'
    )

    //ScrollTrigger.refresh(true)
  },
})
</script>
<style lang="scss" scoped>
section {
  .wrap {
    height: 200vh;

    .headings {
      max-width:90%;
      height: 70px;
      @screen md {
        height: 115px;
      }
    }
  }
}
</style>
