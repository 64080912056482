<template>
  <section>
    <div class="wrap" ref="wrap">
      <div
        class="banner"
        ref="banner6b1"
        style="background-image: url('/assets/home/6-animal.jpg')"
      ></div>
      <div
        class="banner"
        ref="banner6b2"
        style="background-image: url('/assets/home/6-topography.svg')"
      ></div>
      <div ref="content" class="content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 480.24 113.58"
          class="headings"
        >
          <g class="group-1">
            <path
              ref="path1a"
              fill="transparent"
              stroke="#363535"
              stroke-width="2"
              d="M38.16 92.88c0 3.78-1.08 4.86-5 4.86h-6.88c-4 0-5.22-1.08-5.22-4.86V80.1H.36v15.66c0 11.34 5.76 17.82 19.62 17.82h19.44c13.86 0 19.58-6.48 19.58-17.82v-29.7c0-11.34-5.4-17.64-18.9-17.64H25.92c-4 0-5.22-1.08-5.22-4.86V20.7c0-3.78 1.26-4.86 5.22-4.86h6.84c4 0 5 1.08 5 4.86v13h20.92V17.82C58.68 6.48 52.92 0 39.24 0h-19.8C5.58 0 0 6.48 0 17.82v27.9c0 11.34 5 18 18.54 18h14.58c4 0 5 1.08 5 4.86z"
            />
            <path
              ref="path1b"
              fill="transparent"
              stroke="#363535"
              stroke-width="2"
              d="M123.3 0v92.88c0 3.78-1.26 4.86-5.22 4.86h-9.54c-4 0-5-1.08-5-4.86V0H82.8v95.76c0 11.34 5.76 17.82 19.62 17.82h22c13.86 0 19.62-6.48 19.62-17.82V0z"
            />
            <path
              ref="path1c"
              fill="transparent"
              stroke="#363535"
              stroke-width="2"
              d="M218.34 68.76c9.18-1.8 13.14-7.92 13.14-17.28V17.82C231.48 6.48 225.72 0 211.86 0H170.1v113.58h20.7V69.3h6.84l17.28 44.28h22.32zm-7.56-19.62c0 3.78-1.08 4.86-5 4.86H190.8V15.84h14.94c4 0 5 1.08 5 4.86z"
            />
            <path
              ref="path1d"
              fill="transparent"
              stroke="#363535"
              stroke-width="2"
              d="M299.88 0l-14.94 91.8L270.36 0h-21.42l22.5 113.58h27.18L321.12 0h-21.24z"
            />
            <path
              ref="path1e"
              fill="transparent"
              stroke="#363535"
              stroke-width="2"
              d="M360.72 97.74V63.36h27.9V47.34h-27.9v-31.5h33.12V0h-54v113.58h55.44V97.74h-34.56z"
            />
            <path
              ref="path1f"
              fill="transparent"
              stroke="#363535"
              stroke-width="2"
              d="M480.24 0h-21.06L444.6 58.32 429.12 0h-21.06l26.46 77.58v36h20.7V77z"
            />
          </g>
        </svg>

        <p ref="para1">
          Through the use of AI, our unique processing of raw satellite data
          delivers a low-risk, low-cost method of monitoring the world’s
          livestock, wildlife and habitats.
        </p>

        <next ref="next" target="#s8" colour="black"></next>
      </div>
    </div>
  </section>
</template>
<script>
import { defineComponent } from 'vue'
import { gsap } from 'gsap'
//import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Next from '@/components/Next'

export default defineComponent({
  name: 'Banner6b',
  components: { Next },
  mounted() {
    const {
      wrap,
      banner6b1,
      //banner6b2,
      path1a,
      path1b,
      path1c,
      path1d,
      path1e,
      path1f,
      para1,
    } = this.$refs
    const next = this.$refs.next.$el

    let tl = gsap.timeline({
      scrollTrigger: {
        //markers: true,
        trigger: wrap,
        start: 'top 50px',
        pin: true,
        pinSpacing: false,
        anticipatePin: true,
        vars: {
          group: 'g1',
        },
      },
    })
    tl.to(banner6b1, { opacity: 0.5, duration: 1 })
    tl.to(wrap, { backgroundColor: '#FFFFFF', duration: 1 }, '-=1')
    tl.from(path1a, { drawSVG: '50% 50%', duration: 2 }, '-=1')
    tl.from(path1b, { drawSVG: '50% 50%', duration: 2 }, '-=2')
    tl.from(path1c, { drawSVG: '50% 50%', duration: 2 }, '-=2')
    tl.from(path1d, { drawSVG: '50% 50%', duration: 2 }, '-=2')
    tl.from(path1e, { drawSVG: '50% 50%', duration: 2 }, '-=2')
    tl.from(path1f, { drawSVG: '50% 50%', duration: 2 }, '-=2')
    tl.to(path1a, { fill: '#363535', duration: 2 }, '-=1')
    tl.to(path1b, { fill: '#363535', duration: 2 }, '-=2')
    tl.to(path1c, { fill: '#363535', duration: 2 }, '-=2')
    tl.to(path1d, { fill: '#363535', duration: 2 }, '-=2')
    tl.to(path1e, { fill: '#363535', duration: 2 }, '-=2')
    tl.to(path1f, { fill: '#363535', duration: 2 }, '-=2')
    // tl.to(path1a, { stroke: 'transparent', duration: 0.5 })
    // tl.to(path1b, { stroke: 'transparent', duration: 0.5 }, '-=0.5')
    // tl.to(path1c, { stroke: 'transparent', duration: 0.5 }, '-=0.5')
    // tl.to(path1d, { stroke: 'transparent', duration: 0.5 }, '-=0.5')
    // tl.to(path1e, { stroke: 'transparent', duration: 0.5 }, '-=0.5')
    // tl.to(path1f, { stroke: 'transparent', duration: 0.5 }, '-=0.5')

    tl.fromTo(
      para1,
      { opacity: 0, y: 10 },
      { opacity: 1, y: 0, ease: 'power1.out' },
      '-=1'
    )
    tl.fromTo(
      next,
      { autoAlpha: 0 },
      { autoAlpha: 1, ease: 'power1.out', duration: 2 },
      '+=1'
    )

    //ScrollTrigger.refresh(true)
  },
})
</script>
<style lang="scss" scoped>
section {
  .wrap {
    height: 200vh;
    .content {
      p {
        @apply text-black;
      }
    }

    .headings {
      max-width:90%;
      height: 70px;
      @screen md {
        height: 115px;
      }
    }
  }
}
</style>
