<template>
  <main-navigation></main-navigation>
  <mobile-navigation></mobile-navigation>
  <!--  <router-view></router-view>-->
  <router-view v-slot="{ Component }">
    <transition :name="transitionName" :mode="transitionMode">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import { defineComponent } from 'vue'
import MainNavigation from '@/components/MainNavigation.vue'
import MobileNavigation from '@/components/MobileNavigation'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default defineComponent({
  name: 'App',
  components: {
    MainNavigation,
    MobileNavigation,
    // SiteFooter,
  },
  data() {
    return {
      transitionName: null,
      transitionMode: null,
      fadeSections: [
        'Home',
        'About',
        'Services',
        'Contact',
        'PrivacyPolicy',
        'TermsOfUse',
      ],
    }
  },
  watch: {
    $route(to, from) {
      let getScrollTriggerGroup = (group) =>
        ScrollTrigger.getAll().filter((t) => t.vars.group === group)

      if (from.name === 'Home') {
        getScrollTriggerGroup('g1').forEach((t) => t.disable())
      }
      if (to.name === 'Home') {
        getScrollTriggerGroup('g1').forEach((t) => t.enable())
      }

      if (this.transitionName === null || this.fadeSections.includes(to.name)) {
        this.transitionName = 'fade'
        this.transitionMode = 'out-in'
      } else {
        this.transitionName = 'slideUp'
        this.transitionMode = null
      }
    },
  },
})
</script>

<style lang="scss">
#app {
  background-color: #000;
}

.page {
  @apply w-full min-h-screen;
}

// Fade Transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// Slide-Up Transition
.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.75s ease-in;
}

.slideUp-enter-to {
  position: absolute;
  bottom: 0;
}

.slideUp-enter-from {
  position: absolute;
  bottom: -100%;
}

.slideUp-leave-to {
  position: absolute;
  top: -100%;
}

.slideUp-leave-from {
  position: absolute;
  top: 0;
}
</style>
