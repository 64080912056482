<template>
  <div class="mobileNav md:hidden">
    <div class="logo">
      <router-link to="/"><img src="../assets/logo.svg" /></router-link>
    </div>
    <div
      id="burger"
      :class="{ active: isActive }"
      @click="isActive = !isActive"
    >
      <span class="t"></span>
      <span class="m"></span>
      <span class="b"></span>
    </div>
    <div class="drawer" :class="{ active: isActive }">
      <ul>
        <li>
          <router-link to="/" @click="isActive = false">Home</router-link>
        </li>
        <li>
          <router-link to="/about" @click="isActive = false">About</router-link>
        </li>
        <li>
          <router-link to="/services" @click="isActive = false"
            >Services</router-link
          >
        </li>
        <li>
          <router-link to="/contact" @click="isActive = false"
            >Contact</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MobileNavigation',
  data() {
    return {
      isActive: false,
    }
  },
})
</script>
<style lang="scss" scoped>
.mobileNav {
  @apply fixed z-50 pointer-events-none w-full;

  .logo {
    @apply w-full bg-black flex justify-end pointer-events-auto cursor-pointer;
    padding: 10px;
    a {
      @apply block;
    }
  }

  .drawer {
    @apply relative  bg-black pointer-events-auto;

    width: 200px;
    height: 100vh;
    opacity: 0;
    padding: 50px 20px 20px;

    transition: transform 0.5s ease-out, opacity 0.55s ease;

    transform: translateX(-200px);

    &.active {
      transform: translateX(0);
      opacity: 1;
    }

    li {
      padding: 10px 0;
      font-size: 22px;
      color: #fff;
    }
  }

  #burger {
    @apply absolute block pointer-events-auto cursor-pointer;

    top: 20px;
    left: 20px;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;

    span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;

      background: #fff;

      z-index: 1;

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }

    &.active {
      span {
        @apply opacity-100 bg-primary;
        transform: rotate(45deg) translate(6px, 6px);

        &.m {
          @apply opacity-0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        &.b {
          transform: rotate(-45deg) translate(6px, -7px);
        }
      }
    }
  }
}
</style>
